import React from 'react';
import { ExperienceFooterStyle } from './ExperienceFooterStyle';

const ExperienceFooter = () => {
    const handleHistory = () => {
        window.history.pushState({}, '', '/');
    };

    return (
        <ExperienceFooterStyle>
            <button type="button" onClick={handleHistory}>
                VOLTAR
            </button>
        </ExperienceFooterStyle>
    );
};

export default ExperienceFooter;