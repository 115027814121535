import React from "react";
import { colors } from "../../ui/variables";

const PauseIcon = ({ background, color, rounded }) => (
  <svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 25 25">
    <rect
      fill={background || 'transparent'}
      height="25"
      width="25"
      rx={rounded ? 12.5 : 0}
    />
    <g fill={color || colors.black}>
      <rect x="7.9" y="6.37" width="3.07" height="12.27" />
      <rect x="14.03" y="6.37" width="3.07" height="12.27" />
    </g>
  </svg>
);

export default PauseIcon;
