import React, { useEffect, useState, useContext } from 'react';

import { useSelector } from 'react-redux';
import { ListStyle, AccordionStyle, AccordionHeaderStyle, AccordionItemStyle } from './listStyle';

import SlideShow from '../../components/Slideshow/Slideshow';
import TextContent from '../../components/TextContent/TextContent';
import MainQrCode from '../../components/MainQrCode/MainQrCode';
import Player from '../../components/Player/Player';

const List = () => {
    const [experienceList, setExperienceList] = useState([]);
    const [sortedDataAudioguide, setSortedDataAudioguide] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [playingId, setPlayingId] = useState(undefined);

    const dataAudioguide = useSelector((state) => state.audioGuideReducers.dataAudioguide);

    const locale = useSelector((state) => state.audioGuideReducers.lang);

    const id_listagem = 'light';

  
    useEffect(() => {
        if (id_listagem) {
            const listFloor = dataAudioguide.filter((item) => item.slug === id_listagem);
            if (listFloor.length > 0) {
                setExperienceList(listFloor[0].experiences);
                setSortedDataAudioguide(
                    experienceList.sort((a, b) => a.code_number - b.code_number)
                );
            }
            setIsLoading(false);
        } else {
            window.history.pushState({ page: '' }, '', '/');
        }
    }, [experienceList, sortedDataAudioguide, dataAudioguide, id_listagem]);

    const handlePlaying = (index) => {
        if (playingId === index) {
            return setPlayingId(undefined)
        }
        setPlayingId(index)
    }

    const renderExperienceList = (experiences) => {
        console.log(experiences)
        return (
            <AccordionStyle flush className="accordion">
                {experiences.map((item, index) => (
                    <AccordionItemStyle id={`accordion-header${index}`} eventKey={index} key={index} aria-expanded="false" >
                        <AccordionHeaderStyle id={`accordion-button${index}`} onClick={() => handlePlaying(index)} color={item?.color} aria-expanded="false" >
                            {item[`title_${locale}`]}
                        </AccordionHeaderStyle>

                        <AccordionStyle.Body id="accordion-panel" aria-labelledby={`accordion-button${index}`}>
                            <SlideShow content={item.slideshow} />

                            {item[`content_${locale}`].map((content, index) => {
                                if (content?.type === 'text') {
                                    return <TextContent content={content?.text} key={index} />;
                                }
                                if (content?.type === 'audio') {
                                    return (
                                        <Player content={content} key={index} playingId={playingId} />
                                    );
                                }
                                return <></>;
                            })}
                        </AccordionStyle.Body>
                    </AccordionItemStyle>
                ))}
            </AccordionStyle>
        );
    };

    if (isLoading) {
        return <h1>Aguarde...</h1>
    }

    return (
        <ListStyle>
            <MainQrCode />
             {renderExperienceList(experienceList)}
        </ListStyle>
    );
};

export default List;
