import { actionsTypes } from '../constants/constants'

const actions = {
    setDataAudioguide: (dataAudioguide) => ({
        type: actionsTypes.SET_DATA_AUDIOGUIDE, dataAudioguide
    }),
    setAverageRate: (averageRate) => ({
        type: actionsTypes.SET_AVARAGE_RATE, averageRate
    }),
    setOriginRoute: (originRoute) => ({
        type: actionsTypes.SET_ORIGIN_ROUTE, originRoute
    }),
    language: (lang) => ({
        type: actionsTypes.SET_LANG, lang
    }),
    setDisplayMenu: (displayMenu) => ({
        type: actionsTypes.SET_DISPLAY_MENU, displayMenu
    }),
    setDisplayContent: (displayContent) => ({
        type: actionsTypes.SET_DISPLAY_CONTENT, displayContent
    })
}

export { actions }