import styled from 'styled-components';

import { colors, zIndex } from '../../ui/variables.js';


export const LoadingStyle = styled.div`
  ${zIndex.moon};
  background-color: ${colors.black};
  height: 100vh;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;

  &.loaded {
    background-color: transparent;
    transition: background-color 400ms 600ms;

    .logo-wrapper {
      svg {
        *[animation=left] {
          transform: translateX(-100%);
        }

        *[animation=right] {
          transform: translateX(100%);
        }

        *[animation] {
          opacity: 0;
        }
      }
    }  
  }

  .logo-wrapper {
    left: 50%;
    max-width: 390px;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 68vw;

    svg {
      overflow: visible;

      *[animation] {
        opacity: 1;
      }

      *[animation=left],
      *[animation=right] {
        transform: translateX(0);
        transition: transform 600ms, opacity 400ms 200ms;
      }

      *[animation=fade-out] {
        transition: opacity 800ms;
      }
    }
  }
`;
