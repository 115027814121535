import styled from 'styled-components';

import { breakpoints, colors, functions, grid, zIndex } from '../../ui/variables.js';

export const HeaderStyle = styled.div`
  background-color: ${colors.caribbeanGreen};
  justify-content: center;
  height: 160px;
  font-size: 40px;
  max-width: 656px;
  left: 50%;
  position: fixed;
  position: relative;
  top: 0;
  transform: translateX(-50%);
  width: 100%;

div {
  text-align: center;
  padding-top: 10px;
  letter-spacing: 2px;
  
  b{
    display: block;
    margin-top: -20px;
    font-weight: bold;
    font-size: 42px;
    letter-spacing: 3px;
  }
}
`;
