import React, { useEffect, useState, useCallback, createRef } from 'react';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { I18nProvider } from './i18n';
import * as localStorage from './utilities/localStorage';
import { actions } from './actions/actions';
import './App.css';
import NotFound from './views/notFound/notFound';
import Code from './views/qrCode/qrcode';
import List from './views/list/list';
import Experience from './views/experience/experience';
import Header from './components/Header/Header';
import Loading from './components/Loading/Loading';
import MainContent from './components/MainContent/MainContent';
import GlobalStyle from './GlobalStyle';

// Importing the Bootstrap CSS
import 'bootstrap/dist/css/bootstrap.min.css';

const App = () => {
    const [locale, setLocale] = useState();
    //const [firstTime, setFirstTime] = useState(true);
    const [isConstruction] = useState(false);
    const [loaded, setLoaded] = useState(false);

    var urlParams = new URLSearchParams(window.location.search);
    var iniPage = '';
    if (urlParams.get('page')) iniPage = urlParams.get('page');
    //console.log("iniPage", iniPage)

    const [page, setPage] = useState(iniPage);

    const localeDefault = useSelector((state) => state.audioGuideReducers.lang);
    const displayContent = useSelector((state) => state.audioGuideReducers.displayContent);

    const dispatch = useDispatch();

    const setDataAudioguide = useCallback(
        (dataAudioguide) => dispatch(actions.setDataAudioguide(dataAudioguide)),
        [dispatch]
    );

    const setAverageRate = useCallback(
        (averageRate) => dispatch(actions.setAverageRate(averageRate)),
        [dispatch]
    );

    const setLanguage = useCallback((lang) => dispatch(actions.language(lang)), [dispatch]);

    const noderef = createRef();

    window.onpopstate = function (e) {
        //console.log("onpopstate", window.location.search)
        var urlParams = new URLSearchParams(window.location.search);
        setPage(urlParams.get('page') ? urlParams.get('page') : '');
    };

    (function (history) {
        var pushState = history.pushState;
        history.pushState = function (state) {
            if (typeof history.onpushstate == 'function') {
                history.onpushstate({ state: state });
            }
            //console.log("pushState", window.location.search, state.page);
            setPage(state.page ? state.page : '');
            return pushState.apply(history, arguments);
        };
    })(window.history);

    // const handleSelectLanguage = chosenLanguage => {
    //   localStorage.set('lang', chosenLanguage);
    //   setLanguage(chosenLanguage);
    // };

    useEffect(() => {
        const fetchData = () => {
            // axios.get(process.env.REACT_APP_API)
            axios
                .get(process.env.REACT_APP_MOCK)

                .then((response) => {
                    setDataAudioguide(response.data.data);
                    setAverageRate(response.data.averageRate);
                    localStorage.get('lang').then((data) => {
                        if (data !== undefined) {
                            setLocale(data);
                            setLanguage(data);
                            //setFirstTime(false);
                        }

                        setLoaded(true);
                    });
                })
                .catch((error) => {
                    console.log(error.message);
                    console.log('API Não Conectada');
                });
        };

        fetchData();
    }, [setDataAudioguide, setAverageRate, setLanguage, localeDefault]);

    return (
        <I18nProvider locale={locale}>
            <GlobalStyle />
            <MainContent>
                <Header />
                {isConstruction ? (
                    <div style={{ textAlign: 'center', marginTop: 180 }}>
                        <h1>PÁGINA EM CONSTRUÇÃO</h1>
                    </div>
                ) : loaded ? (
                    <>
                        {/* selectLanguage={handleSelectLanguage} */}
                        {displayContent ? (
                            <div className={`main-content-wrapper`}>
                                <div noderef={noderef} key={page}>
                                    <div ref={noderef}>
                                        {page === '' ? (
                                            <List></List>
                                        ) : page === 'experiencia' ? (
                                            <Experience></Experience>
                                        ) : page === 'listagem' ? (
                                            <List></List>
                                        ) : page === 'codigo' ? (
                                            <Code></Code>
                                        ) : (
                                            <NotFound></NotFound>
                                        )}
                                    </div>
                                </div>
                            </div>
                        ) : null}

                        {/* <NavigationBar currentLanguage={locale} /> */}
                    </>
                ) : (
                    <Loading loaded={loaded} />
                )}
            </MainContent>
        </I18nProvider>
    );
};

export default App;
