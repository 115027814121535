import styled from 'styled-components';

export const CodeStyle = styled.div`
    margin-bottom: -10px;

    img {
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: 15%;
        height: 15%;
        margin-bottom: 20px;
        margin-top: -45px;
    }
`

export const ImageStyle = styled.img`
width: 150px;
height: 150px;

`