import styled from 'styled-components';
import Accordion from 'react-bootstrap/Accordion';

export const AccordionStyle = styled(Accordion)`
    padding: 0 18px 20px 18px;
`;

export const AccordionItemStyle = styled(Accordion.Item)`
    margin: 4px 0 4px 0;
`;

export const AccordionHeaderStyle = styled(Accordion.Header)`
    .accordion-button {
        letter-spacing: 2px;
        font-weight: 600;
        color: #fff;
        background-color: ${(props) => props.color};
       }

    .accordion-button:focus {
        border-color: none;
        outline: 0;
        box-shadow: none;
    }

    .accordion-button::after {
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23FFFFFF'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
    }

    .accordion-button:not(.collapsed)::after {
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23FFFFFF'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
    }
`;

export const ListStyle = styled.div`
    padding-top: 20px;
    
`;
