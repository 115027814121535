import React from "react";
import translate from '../../i18n/translate'
import { NotFoundStyle } from './notFoundStyle';


const NotFound = () => {
  return (
    <NotFoundStyle>
      <h2>{translate("contentNotAvailable")}</h2>
    </NotFoundStyle>
  )
}

export default NotFound