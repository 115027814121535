import React, { useEffect } from 'react';
import { SlideshowStyle } from './SlideshowStyle.js';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel'; 

const SlideShow = ({ content }) => {
    useEffect(() => {
        var dots = document.getElementsByClassName('dot');
        for (var i = 0; i < dots.length; i++) {
            dots.item(i).ariaLive = 'assertive';
            dots.item(i).ariaHidden = 'true';
        }
    }, [content]);



    return (
        <SlideshowStyle aria-hidden={true}>
            <Carousel
                showThumbs={false}
                showStatus={false}
                showArrows={false}
                autoPlay={false}
                infiniteLoop={true}
                interval={4000}
            >
                {content.map((item) => {
                    var imgURL = item.url;
                    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
                        imgURL = 'http://localhost:1340/' + imgURL;
                    }

                    return (
                        <div role="img"
                            // aria-selected="true"
                            aria-label={item.name}
                            key={item.id}
                            className="slideshow-item"
                            style={{
                                backgroundImage: `url(${imgURL})`,
                            }}
                        />
                    );
                })}
                </Carousel>
        </SlideshowStyle>
    );
};

export default SlideShow;
