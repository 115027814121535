import styled from 'styled-components';

import { breakpoints, functions, zIndex } from '../../ui/variables.js';

export const SlideshowStyle = styled.div`
  .slideshow-item {
    background-position: center;
    background-size: cover;
    height: ${functions.responsive.vw(
      120,
      172,
      breakpoints.iphone.width,
      breakpoints.iphoneXR.width)};
    height: 120px;
    width: 100%;

    @media (min-width: ${breakpoints.iphoneXR.width}px) {
      height: ${functions.responsive.vw(
        172,
        320,
        breakpoints.iphoneXR.width,
        breakpoints.ipad.width)};
    }

    @media (min-width: ${breakpoints.ipad.width}px) {
      height: 320px;
    }

    .item-image-file {
      // ${zIndex.hills};
      display: block;
      height: 100%;
      left: 0;
      opacity: 0;
      position: absolute;
      top: 0;
      width: 100%;
    }
  }
`;



