import React from "react";
import { colors } from "../../ui/variables";

const PlayIcon = ({ background, color, rounded }) => (
  <svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 25 25">
    <rect
      fill={background || 'transparent'}
      height="25"
      width="25"
      rx={rounded ? 12.5 : 0}
    />
    <path d="M19,12.37,9,18.63V6.37Z" fill={color || colors.black} />
  </svg>
);

export default PlayIcon;
