import React, { useState, useEffect, createRef, useRef } from 'react';
import { useSelector } from 'react-redux';
import { CSSTransition } from 'react-transition-group';
import { injectIntl } from 'react-intl';
import SlideShow from '../../components/Slideshow/Slideshow';
import TextContent from '../../components/TextContent/TextContent';
import ExperienceFooter from '../../components/ExperienceFooter/ExperienceFooter';
import Player from '../../components/Player/Player';
import { ExperienceStyle } from './experienceStyle';
import * as localStorage from '../../utilities/localStorage';
import NotFound from '../notFound/notFound'

const Experience = ({ intl }) => {
  var urlParams = new URLSearchParams(window.location.search);
  var iniCodeNumber = ""
  if (urlParams.get("codigo"))
    iniCodeNumber = urlParams.get("codigo");

  const [experience, setExperience] = useState({ code_number: iniCodeNumber });
  const [isLoading, setLoading] = useState(true);
  const [playingId, setPlayingId] = useState('');
  const numberRef = useRef()
  const titleRef = useRef()

  const dataAudioguide = useSelector(state => state.audioGuideReducers.dataAudioguide);
  const locale = useSelector(state => state.audioGuideReducers.lang);


  let [showTitle, setShowTitle] = useState(false);
  let [showCodeNumber, setShowCodeNumber] = useState(false);
  let [showContent, setShowContent] = useState(false);

  useEffect(() => {
    setShowContent(false)
    setTimeout(() => setShowTitle(true), 100);
    setTimeout(() => setShowCodeNumber(true), 1000);
    setTimeout(() => setShowContent(true), 1000);
  }, [locale])

  const timeoutTransition = {
    title: 600,
    codeNumber: 600,
    text: 600,
    audio: 600,

  }

  useEffect(() => {
    localStorage.get("viewHistory", []).then(data => {
      data.push(experience.code_number || experience.id)
      localStorage.set("viewHistory", data);
    });
  }, [experience])

  useEffect(() => {
    let idExpUrl = experience.code_number;

    if (idExpUrl) {
      let experiencesList = dataAudioguide.map(item => item.experiences);
      let mergedExperiences = [].concat.apply([], experiencesList);
      let experience = [];

      if (idExpUrl.length < 3) {
        experience = mergedExperiences.filter(item => item.code_number === idExpUrl);
      } else {
        experience = mergedExperiences.filter(item => item.id === idExpUrl);
      }

      if (experience[0]) {
        setExperience(experience[0]);
        setLoading(false);
      } else {
        window.history.pushState({ page: "" }, "", "/");
      }
    } else {
      window.history.pushState({ page: "" }, "", "/");
    }
  }, [dataAudioguide, experience.code_number]);

  const playContent = id => setPlayingId(id);

  const renderExperienceContent = (content, index) => {
    var nodeRef = createRef()
    if (
      content &&
      content.id &&
      content.type &&
      content.type.length
    ) {
      switch (content.type) {
        case 'text':
          if (content.text && content.text.length) {
            return (
              <div key={content.id}>
                <TextContent
                  key={content.id}
                  customRef={nodeRef}
                  content={content.text}
                />
              </div>
            );
          }

          return null;

        case 'audio':
          if (content.url && content.url.length) {
            if (content.url.substring(0, 4) !== "http") {
              content.url = "https://" + content.url;
            }
            return (
              <Player content={content} key={index} />
            );
          }

          return null;

        case 'video':
          return null;

        default:
          return null;
      }
    }
  };

  return (
    <ExperienceStyle>
      {isLoading
        ? (<></>) :
        (
          <>
            <SlideShow content={experience.slideshow} />
            <div className="experience-content-wrapper">
              <CSSTransition nodeRef={titleRef} in={showTitle} timeout={timeoutTransition.title} classNames="transition-title">
                <h2 className="experience-title" ref={titleRef} aria-label={experience.title_pt}>
                  {experience[`title_${locale}`]}
                </h2>
              </CSSTransition>

              {experience && experience.code_number
                ? (
                  <CSSTransition nodeRef={numberRef} in={showCodeNumber} timeout={timeoutTransition.codeNumber} classNames="transition-code-number" key={experience.code_number}>
                    <p ref={numberRef} className="experience-code" aria-label={intl.formatMessage({ id: 'experience' }) + ' ' + experience.code_number}>
                      {experience.code_number}
                    </p>
                  </CSSTransition>
                ) : null}

              {
                experience[`content_${locale}`].length === 0 ? <NotFound /> :
                  experience[`content_${locale}`].map((content, index) => {
                    return renderExperienceContent(content, index);
                  })
              }
              <ExperienceFooter />
            </div>
          </>
        )}
    </ExperienceStyle>
  );
};

export default injectIntl(Experience);
