import { createGlobalStyle } from 'styled-components';

import { breakpoints, colors, grid } from './ui/variables';

const GlobalStyle = createGlobalStyle`
  html {
    overflow-x: hidden;
    width: 100%;

    body {
      background-color: ${colors.black};
      color: ${colors.white};
      max-width: 100vw;
      overflow-x: hidden;
      width: 100%;
      position: relative;

      * {
        box-sizing: border-box;
        margin: 0;
        padding: 0;
      }

      ul,
      ol {
        list-style: none;

        li {
          list-style: none;
        }
      }

      a {
        color: inherit;
        font-family: inherit;
        font-weight: inherit;
        text-decoration: none;
      }

      input,
      textarea,
      button {
        -webkit-appearance: none;
        background: transparent;
        border: none;
        color: inherit;
        cursor: pointer;
        font: inherit;
        line-height: normal;
        outline: none;
      }

      textarea {
        -moz-box-shadow: none;
        -webkit-box-shadow: none;
        box-shadow: none;
        overflow: auto;
        resize: none;
      }

      svg {
        display: block;
        height: auto;
        width: 100%;
      }

      .language-selector-wrapper {
        background-color: ${colors.black};
        display: flex;
        flex-direction: column;
        justify-content: center;
        left: 50%;
        max-width: ${breakpoints.ipad.width}px;
        min-height: 100vh;
        padding: ${grid.minOuter}px;
        position: fixed;
        top: 0;
        transform: translateX(-50%);
        width: 100%;

        @media (min-width: ${breakpoints.iphoneX.width}px) {
          padding: 56px;
        }
        
        // .language-title-logo {
        //   margin-bottom: 60px;

        //   .logo-wrapper {
        //     display: inline-block;
        //     width: 128px;
        //   }
        // }
      }

      .page-slider-enter {
        transition: translate3d(-100%, 0, 0);
      }

      .page-slider-enter-active {
        transition: translate3d(0, 0, 0);
      }

      .page-slider-exit {
        transition: translate3d(0, 0, 0);
      }

      .page-slider-enter-active {
        transition: translate3d(-100%, 0, 0);
      }
    }
  }
`;

export default GlobalStyle;
