import React, { useState, useEffect, useCallback } from 'react';
import { QrReader } from 'react-qr-reader';
import { useDispatch} from 'react-redux';
import { injectIntl } from 'react-intl';
import translate from '../../i18n/translate';

import { actions } from '../../actions/actions';
import QrCodeTarget from '../../components/svgs/QrCodeTarget';
import Footer from '../../components/Footer/Footer';
import { QrCodeStyle } from './qrcodeStyle';

const QrCode = ({ intl }) => {
  const dispatch = useDispatch();
  const setOriginRoute = useCallback(routeChoice => dispatch(
    actions.setOriginRoute(routeChoice)
  ), [dispatch]);

  // const [code, setCode] = useState('');
  // const [result, setResult] = useState('');
  const [webcamEnabled, setWebcamEnabled] = useState(true);
  const [codeNotFound, setCodeNotFound] = useState(false);
  // const dataAudioguide = useSelector(state => state.audioGuideReducers.dataAudioguide);

  useEffect(() => { setOriginRoute('code') }, [setOriginRoute])

  const openQrCodeLink = data => {
    console.log("url", data.text);
    window.open(data.text, '_self');
  }

  const onClickAllowWebcan = () => {
    alert("abriu camera")
    navigator.getUserMedia(
      // constraints
      {
        video: true,
        audio: true
      },

      // successCallback
      function (localMediaStream) {
        //user allowedpdate the permission setting
        setWebcamEnabled(true);
      },

      // errorCallback
      function (err) {
        // Explain why you need permission and how to update the permission setting
        alert('Para usar o leitor de QRCode é necessário permitir o acesso à camera.');
      }
    );
  }

  return (
    <QrCodeStyle>
      <p className={`code-instruction${codeNotFound ? ' code-not-found' : ''}`}>
        {
          codeNotFound ? translate('msgQRCodeError') : translate('msgQRCode')
        }
      </p>
      <div className="qr-code-wrapper">
        {webcamEnabled
          ? (
            <QrReader
              constraints={{
                facingMode: 'environment'
              }}
              scanDelay={300}
              onResult={(result, error) => {
                if (!!result) {
                  openQrCodeLink(result);
                }
                if (!!error) {
                  if (error.name === "NotAllowedError") {
                    setWebcamEnabled(false);
                  }
                }
              }}
            />
          ) : null
        }
        <div className="code-target">
          <QrCodeTarget allowed={webcamEnabled} />
          {!webcamEnabled
            ? (
              <button
                type="button"
                className="allow-webcan"
                onClick={onClickAllowWebcan}
              >
                {translate('allowCam')}
              </button>
            ) : null
          }
        </div>
      </div>
      <Footer />
    </QrCodeStyle>
  );
};
export default injectIntl(QrCode);