import React from 'react';
import qrcodelogo2 from '../../ui/qrcodelogo2.png';
import {CodeStyle} from './MainQrCodeStyle'

const MainQrCode = () => {
    const handleClick = () => {
        window.history.pushState({page:"codigo"}, "", "?page=codigo");
    }

    return (
        <CodeStyle onClick={handleClick}>
            <img aria-label="abrir leitor de código." 
                src={qrcodelogo2} 
                alt="abrir leitor de código." 
            />
        </CodeStyle>
    )
}

export default MainQrCode;