import styled from 'styled-components';

import { colors, fontFamily } from '../../ui/variables.js';

export const TextContentStyle = styled.article`
  color: #635e5e;
  margin-top: 8px;
  margin-bottom: 12px;
  font-size: 16px;
  letter-spacing: -0.24px;
  line-height: 26px;

  .text-content-wrapper {
    &.truncable {
      box-sizing: content-box;
      overflow: hidden;
      padding-bottom: 40px;
      position: relative;

      .toggle-visibility-wrapper {
        background-color: ${colors.black};
        bottom: 0;
        display: block;
        left: 0;
        position: absolute;
        text-align: center;
        width: 100%;

        .toggle-visibility {
          ${fontFamily.sohneKraftig};
          color: ${colors.vividOrange};
          font-size: 14px;
          letter-spacing: -0.24px;
          line-height: 16px;
          padding: 12px 0;
        }
      }

    }

    &.visible {
      max-height: 1000em;
      transition: max-height 600ms;

      &:after {
        display: none;
      }
    }

    .toggle-visibility-wrapper {
      display: none;
    }

    .text-cont {
      h2 {
        ${fontFamily.sohneKraftig};
        color: ${colors.white};
        font-size: 16px;
        letter-spacing: -0.24px;
        line-height: 21px;

        & + h3,
        & + h4 {
          margin-top: 2px;
        }

        & + p {
          margin-top: 12px;
          
        }
      }

      h3,
      h4 {
        ${fontFamily.sohneLeicht};
        color: ${colors.vividOrange};
        font-size: 14px;
        letter-spacing: -0.21px;
        line-height: 18px;
        margin-bottom: 12px;
      }

      p,
      ul {
        margin-bottom: 12px;

        &:last-child {
          margin-bottom: 0;
        }
      }

      ul,
      ol {
        padding-left: 40px;
      }

      ul {
        li {
          list-style: disc;
        }
      }

      ol {
        li {
          list-style: decimal;
        }
      }
    }
  }
`;
