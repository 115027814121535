import React, { useEffect, useState } from 'react';

//import Logo from '../Logo/Logo';

import { LoadingStyle } from './LoadingStyle.js';


const Loading = ({ loaded }) => {
  const [destroy, setDestroy] = useState(false);

  useEffect(() => {
    setTimeout(() => setDestroy(true), 1200)
  }, [loaded]);

  return (
    <>
      {
        !destroy
          ? (
            <LoadingStyle className={loaded ? 'loaded' : ''}>
              <div className="logo-wrapper">
                {/* <Logo /> */}
              </div>
            </LoadingStyle>
          )
          : null
      }
    </>
  );
};

export default Loading;
