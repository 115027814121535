import React from 'react';

import { colors } from '../../ui/variables';

const QrCodeTarget = ({ allowed }) => {
  const withteCornerStyle = {
    fill: 'none',
    stroke: allowed ? colors.white : colors.darkGray,
    strokeMiterlimit: 10,
    strokeWidth: '6px',
    vectorEffect: 'non-scaling-stroke',
  };

  const redLineBoxStyle = {
    fill: 'none',
    stroke: colors.vividRed,
    strokeMiterlimit: 10,
    strokeWidth: '1px',
    vectorEffect: 'non-scaling-stroke'
  }

  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 320">
      <g opacity={ allowed ? 0 : 1 }>
        <rect x="33" y="33" width="254" height="254" rx="4" fill="#1a1a1a" />
        <g fill="#2e2e2e">
          <rect x="212" y="156" width="8" height="8" />
          <polygon points="164 180 172 180 172 188 180 188 180 172 172 172 172 164 180 164 180 172 204 172 204 164 188 164 188 148 164 148 164 156 148 156 148 164 148 172 156 172 156 180 148 180 148 188 164 188 164 180" />
          <path d="M84,124h40V84H84Zm8-32h24v24H92Z" />
          <rect x="172" y="196" width="8" height="8" />
          <rect x="188" y="188" width="8" height="8" />
          <rect x="196" y="196" width="16" height="8" />
          <path d="M124,196H84v40h40Zm-8,32H92V204h24Z" />
          <path d="M50,50V270H270V50ZM76,76h56v56H76Zm0,88h8v8h16V148H84v8H76V140h24v8h16v-8h16v8H116v8h16v8H108v16H76Zm56,80H76V188h56Zm48-8h-8v-8h8Zm64,8h-8v-8h8Zm0-16H220v16h-8v-8H196v8h-8v-8h8v-8h-8v-8h16v-8H164v8H148v8h8v8h8v8H140V220h8V204h8v-8h-8v-8h-8v-8H116v-8h24V108h8V92h-8V76h40V92h-8v8h8v16h-8v-8h-8v-8h-8v8h-8v24h8V116h8v16h8v-8h8v8h-8v8h40v8h8v8h8v8h-8v16h8v-8h8v8h-8v16h-8v16h8v-8h8v8h-8v8h16ZM140,204v-8h8v8Zm104-8h-8v-8h8Zm0-32h-8V148h-8v-8h16Zm0-32H188V76h56Z" />
          <rect x="204" y="172" width="8" height="8" />
          <rect x="196" y="148" width="8" height="8" />
          <rect x="156" y="84" width="8" height="8" />
          <path d="M236,84H196v40h40Zm-8,32H204V92h24Z" />
        </g>
      </g>
      <polyline points="39 16 16 16 16 39" {...withteCornerStyle} />
      <polyline points="281 16 304 16 304 39" {...withteCornerStyle} />
      <polyline points="304 281 304 304 281 304" {...withteCornerStyle} />
      <polyline points="16 281 16 304 39 304" {...withteCornerStyle} />
      <path d="M294,26V294H26V26H294m1-1H25V295H295V25Z" {...redLineBoxStyle} />
      <path d="M0,0V320H320V0ZM307,307H13V13H307Z" style={{ opacity: 0.6 }} />
    </svg>
  );
};

export default QrCodeTarget;
