import styled from "styled-components";

export const PlayerContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 52px;
    width: 100%;
`

export const ButtonPlayer = styled.button`
    width: 24px;
    margin-top: -7px;
`

export const ContainerProgress = styled.div`
    display: flex;
    height: 10px;
    width: 90%;
`

export const ProgressBar = styled.div`
    background-color: grey;
    width: 100%; 
    height: 2px;
`

export const ProgressBarCurrent = styled.div`
    /* position: absolute; */
    background-color: #FD7122;
    width: ${(props) => props.percentageProgress}; 
    height: 3px;  
`
