import React from 'react';
import { FooterStyle } from './FooterStyle';

const Footer = () => {
    const handleHistory = () => {
        window.history.pushState({}, '', '/');
    };

    return (
        <FooterStyle>
            <button type="button" onClick={handleHistory}>
                VOLTAR
            </button>
        </FooterStyle>
    );
};

export default Footer;
