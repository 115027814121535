import React from 'react';
import { HeaderStyle } from './HeaderStyle';

const Header = () => {
    return (
        <HeaderStyle role="heading" aria-level="1"> 
            
         
          <div>
            museu Light <b>da energia</b>
          </div>
          
            
        </HeaderStyle>
    )
}

export default Header;