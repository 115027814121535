import styled from 'styled-components';

import { breakpoints, colors, mixins, zIndex } from '../../ui/variables.js';

export const pageTransitionTime = 550;

export const MainContentStyle = styled.main`
  ${mixins.marginLeftRightAuto};
  max-width: ${breakpoints.ipad.width}px;
  min-height: 100vh;
  overflow: hidden;

  
  &:after {
  //   // background: ${colors.black};
  //   // background: linear-gradient(0, rgba(0, 0, 0, 1) 10%, rgba(0, 0, 0, 0) 100%);
    bottom: 0;
    // content: '';
    display: block;
    // height: 68px;
    position: fixed;
    // width: 100%;

    @media (min-height: ${breakpoints.iphoneSe.height}px) {
      height: 78px;
    }

    @media (min-height: ${breakpoints.iphone8.height}px) {
      height: 84px;
    }

    @media (min-height: ${breakpoints.iphonePlus.height}px) {
      height: 90px;
    }

    @media (min-height: ${breakpoints.iphoneX.height}px) {
      height: 94px;
    }
   }
  

  .main-content-wrapper {
    margin-top: 60px;
    position: relative;

    @media (min-height: ${breakpoints.iphoneSe.height}px) {
      margin-top: 62px;
    }

    @media (min-height: ${breakpoints.iphonePlus.height}px) {
      margin-top: 68px;
    }

    @media (min-height: ${breakpoints.iphoneX.height}px) {
      margin-top: 88px;
    }

    &:before,
    &:after {
      ${zIndex.space};
      background-color: ${colors.black};
      content: '';
      display: block;
      height: 100vh;
      position: fixed;
      top: 0;
      width: calc((100vw - ${breakpoints.ipad.width}px) / 2);
    }

    &:before {
      left: 0;
    }

    &:after {
      right: 0;
    }

    &.right {
      .page-transition-enter-active {
        animation: left ${pageTransitionTime}ms ease-in reverse;
      }

      .page-transition-exit-active {
        animation: right ${pageTransitionTime}ms ease-out;
      }
    }

    &.left {
      .page-transition-enter-active {
        animation: right ${pageTransitionTime}ms ease-in reverse;
      }

      .page-transition-exit-active {
        animation: left ${pageTransitionTime}ms ease-out;
      }
    }

    &.none {
      .page-transition-enter-active {
        animation: none;
      }

      .page-transition-exit-active {
        display: none;
      }
    }
  }

  @keyframes left {
    0% {
      transform: translate3d(0, 0, 0);
    }

    100% {
      transform: translate3d(100%, 0, 0);
    }
  }

  @keyframes right {
    0% {
      transform: translate3d(0, 0, 0);
    }

    100% {
      transform: translate3d(-100%, 0, 0);
    }
  }
`;
