import styled from 'styled-components';

export const ExperienceFooterStyle = styled.footer`
color: black;
margin-top: 10px;
margin-bottom: 50px;
align-items: center;
display: flex;
justify-content: center;
font-weight: 500;
`
