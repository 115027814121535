import React, { useRef, useState, useCallback, useEffect } from 'react';
import PlayIcon from '../svgs/PlayIcon';
import PauseIcon from '../svgs/PauseIcon';
import { ButtonPlayer, ContainerProgress, PlayerContainer, ProgressBar, ProgressBarCurrent } from './PlayerStyle';
import { TailSpin } from 'react-loader-spinner'


const Player = ({ content, playingId }) => {
    const [isPlaying, setIsPlaying] = useState(false)
    const [percentage, setPercentage] = useState(0)
    const [currentTime, setCurrentTime] = useState(0)
    const [isLoading, setIsLoading] = useState(false)
    const [currentPLayingId, setCurrentPlayingId] = useState("")

    const audioRef = useRef();

    const setupProgress = useCallback(() => {
        setCurrentTime(audioRef?.current?.currentTime)
    }, []);

    const handlePlayAndPause = () => {
        setIsLoading(true)
        if (isPlaying) {
            setIsPlaying(false)
            setIsLoading(false)
            audioRef.current.pause();
        } else {
            setIsPlaying(true);
            audioRef.current.play();
        }
    }

    const onClickedProgress = (event) => {
        const offsetX = event.nativeEvent.offsetX;
        const offsetWidth = event.currentTarget.offsetWidth;
        audioRef.current.currentTime = (offsetX / offsetWidth) * audioRef?.current?.duration;
    }

    const onAudioEnded = () => {
        setIsPlaying(false);
        setCurrentTime(0);
        setPercentage(0);
        audioRef.current.pause();
    }

    useEffect(() => {
        if (currentTime) {
            setIsLoading(false)
            setPercentage((audioRef?.current?.currentTime * 100) / audioRef?.current?.duration)
        }
    }, [currentTime])

    useEffect(() => {
        if (currentPLayingId !== playingId) {
            setCurrentPlayingId(playingId)
            onAudioEnded()
        }
        if (playingId === "" || playingId === undefined) {
            onAudioEnded()
        }
    },[playingId, currentPLayingId])

    return (
        <>
            <PlayerContainer>
                <ButtonPlayer onClick={handlePlayAndPause} role="button" ariaLabel="">
                    {isLoading ? <TailSpin color="#000" ariaLabel="loading-indicator" /> :
                        isPlaying ? <PauseIcon background={"#fff"} color={"#000"} rounded /> :
                            <PlayIcon background={"#fff"} color={"#000"} rounded />}
                </ButtonPlayer>

                <ContainerProgress>
                    <ProgressBar onClick={onClickedProgress}>
                        <ProgressBarCurrent percentageProgress={`${percentage}%`} />
                    </ProgressBar>
                </ContainerProgress>
            </PlayerContainer>

            <audio src={content.url} controls ref={audioRef} onTimeUpdate={setupProgress} onEnded={onAudioEnded} hidden >
                <p> Esse navegador não reproduz áudio</p>
            </audio>

        </>

    )
}

export default Player;