import styled from 'styled-components';

import { colors, grid } from '../../ui/variables.js';

export const ExperienceStyle = styled.div`
  padding-top: 0px;
  padding-bottom: 15px;
  background-color: ${colors.white};
  max-width: 656px;
  left: 50%;
  position: fixed;
  position: relative;
  top: 0;
  transform: translateX(-50%);
  width: 100%;
  

  .experience-content-wrapper {
    padding: 18px ${grid.minOuter}px 0;
    position: relative;
    max-width: 656px;

    .experience-title {
      color: ${colors.veryDarkGray};
      transition: opacity 500ms, transform 500ms;
      opacity: 0;
      transform: translateY(-100px);

      font-size: 24px;
      letter-spacing: -0.36px;
      line-height: 25px;
      margin-bottom: 5px;
      padding-bottom: 0px;
      padding-right: 50px;
      position: relative;

      &.transition-title-enter,
      &.transition-title-enter-active,
      &.transition-title-enter-done {
        opacity: 1;
        transform: translateY(0);
      }


    .experience-code {
      background-color: ${colors.warmOrange};
      border-radius: 50%;
      color: ${colors.darkGray};
      font-size: 18px;
      height: 25px;
      letter-spacing: -0.27px;
      line-height: 19px;
      padding-top: 2px;
      position: absolute;
      right: ${grid.minOuter}px;
      text-align: center;
      top: 22px;
      width: 25px;
      transition: opacity 500ms;
      opacity: 0;

    &.transition-code-number-enter,
    &.transition-code-number-enter-active,
    &.transition-code-number-enter-done {
      opacity: 1;
    }
  }
  

/* Content */
    .content {
      opacity: 0;
      transition: opacity 1s;
      margin-bottom: 30px;
    }
    .transition-content-enter,
    .transition-content-enter-active,
    .transition-content-enter-done {
      opacity: 1;
    }

`;
